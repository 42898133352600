
import Vue from "vue";

export default Vue.extend({
	name: "PermissionDenied",
	data: () => ({}),
	created() {},
	computed: {},
	methods: {
		handleBack() {
			this.$router.push({ name: "Root" });
		},
	},
});
